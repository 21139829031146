<template>
  <v-container
    fluid
    tag="section"
  >
    <!--  -->
    <v-row justify="center">
      <!--  -->
      <v-col
        cols="12"
        md="8"
      >
        <v-card class="mx-auto">
          <v-img
            class="white--text align-end img-height"
            :src="forum.data.cover.image.large"
          >
            <v-card-title>{{ forum.data.title }}</v-card-title>
          </v-img>
          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ forum.data.user.name }} ({{
                    forum.data.created_at | moment('D MMM YYYY')
                  }})
                </v-list-item-title>
              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >
                <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">{{
                  forum.data.count_likes
                }}</span>
              </v-row>
            </v-list-item>
          </v-card-actions>

          <v-card-text class="text--primary">
            <div>
              <p>
                {{ forum.data.post }}
              </p>
            </div>
          </v-card-text>
          <div class="d-flex flex-no-wrap justify-center align-center">
            <v-card-actions>
              <v-btn
                :disabled="meta_comments.next === null"
                color="orange"
                text
                @click="getComments(meta_comments.current_page + 1)"
              >
                Lebih banyak komentar
              </v-btn>
            </v-card-actions>
          </div>
          <v-card-text class="text--primary">
            <div
              v-if="!isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <v-col
              v-for="(item, i) in comments"
              :key="item.id"
              cols="12"
            >
              <v-card class="mt-n5">
                <div class="d-flex flex-no-wrap justify-start align-start">
                  <div class="d-flex flex-column align-center">
                    <v-avatar
                      class="ma-3"
                      size="32"
                    >
                      <v-img
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      />
                    </v-avatar>
                  </div>

                  <div class="d-flex flex-column align-start flex-grow-1">
                    <h1>
                      <v-card-title
                        class="text--primary ml-n3"
                        v-text="item.user.name"
                      />
                    </h1>
                    <v-card-text
                      class="text--primary mt-n5 ml-n3"
                      v-text="item.post"
                    />
                  </div>
                  <div class="d-flex align-center align-self-sm-center">
                    <v-tooltip
                      bottom
                      color="red"
                    >
                      <!--  -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                          @click="delComments(item.thread_id, item.id, i)"
                        >
                          <v-icon>
                            mdi-delete-forever
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="mt-n5">
                <div class="d-flex flex-no-wrap justify-start align-start">
                  <div class="d-flex flex-column align-center">
                    <v-avatar
                      class="ma-3"
                      size="32"
                    >
                      <v-img
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      />
                    </v-avatar>
                  </div>

                  <div class="d-flex flex-column align-start flex-grow-1">
                    <v-form
                      style="width: 100%"
                      @submit.enter.prevent="sendComments"
                    >
                      <v-text-field
                        v-model="post"
                        label="komentar"
                        placeholder="Ketik Disini"
                      />
                    </v-form>
                  </div>
                  <div class="d-flex align-center align-self-sm-center">
                    <v-btn
                      color="blue"
                      icon
                      @click.prevent="sendComments"
                    >
                      <v-icon>
                        mdi-send
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    data () {
      return {
        forum: {
          meta: {},
          data: {
            title: '',
            status: '',
            post: '',
            cover: {
              image: {
                large: '',
              },
            },
            user: {
              name: '',
            },
            created_at: '2020-10-10 00:00:00',
          },
        },
        comments: null,
        meta_comments: {
          next: null,
        },
        status_items: ['open', 'close'],
        categoryRules: [value => value !== '' || 'Kategori wajib diisi'],
        isEditing: false,
        isLoading: false,
        post: '',
      }
    },
    mounted () {
      this.getForum()
      this.getComments()
    },
    methods: {
      getUpdate () {
        axios
          .patch('/v1/forum/thread/' + this.$route.params.id, {
            title: this.forum.data.title,
            post: this.forum.data.post,
            status: this.forum.data.status,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/forum' })
            }
            // update
            this.$toast.success('Berhasil Di Sunting', {
              type: 'success',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          })
      },
      setForum (data) {
        this.forum = data
      },
      getForum () {
        const params = {
          entities: 'category,cover,user',
        }
        axios
          .get('/v1/forum/thread/' + this.$route.params.id, {
            params: params,
          })
          .then(response => {
            this.setForum(response.data)
          })
          .catch(e => {
            console.error(e)
          })
      },
      sendComments () {
        axios
          .post('/v1/forum/thread/' + this.$route.params.id + '/comment', {
            post: this.post,
          })
          .then(response => {
            if (response.data.meta.status) {
              const item = response.data.data
              this.comments.push(item)
              this.post = ''
              this.$toast.success('Komentar Terkirim', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data.meta.message)
            }
          })
      },
      setComments (data) {
        if (this.comments === null) {
          this.comments = data.data.reverse()
          this.meta_comments = data.meta
        } else {
          this.meta_comments = data.meta
          const items = data.data
          items.map(item => {
            this.comments.unshift({ ...item })
          })
        }
      },
      delComments (thread, id, i) {
        axios
          .delete('/v1/forum/thread/' + thread + '/comment/' + id)
          .then(response => {
            if (response.data.meta.status) {
              this.comments.splice(i, 1)
              this.$toast.error('Berhasil menghapus data', {
                type: 'error',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data)
            }
          // Update
          })
      },
      getComments (page = 1) {
        this.isLoading = false
        const params = {
          entities: 'user',
          sort: '-id',
          page: page,
          offset: 5,
        }
        axios
          .get('/v1/forum/thread/' + this.$route.params.id + '/comment', {
            params: params,
          })
          .then(response => {
            this.setComments(response.data)
            this.isLoading = true
          })
          .catch(e => {
            console.error(e)
          })
      },
    },
  }
</script>

<style>
@media screen and (max-width: 4904px) {
  .img-height {
    height: 400px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .img-height {
    height: 200px;
  }
}
</style>
